.column {
  > .scrollable {
    background: $ui-base-color;
  }

  .account__header {
    background: lighten($ui-base-color, 4%);

    &__fields {
      background: darken($ui-base-color, 4%);
    }
  }

  &-back-button,
  &-header {
    background: lighten($ui-base-color, 4%);

    &__collapsible {
      background: lighten($ui-base-color, 8%);
    }
  }
}

.drawer {
  &__header {
    background: lighten($ui-base-color, 8%);
  }

  &__inner {
    background: lighten($ui-base-color, 13%);
  }

  &__inner__mastodon {
    background: lighten($ui-base-color, 13%)
      url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 234.80078 31.757813" width="234.80078" height="31.757812"><path d="M19.599609 0c-1.05 0-2.10039.375-2.90039 1.125L0 16.925781v14.832031h234.80078V17.025391l-16.5-15.900391c-1.6-1.5-4.20078-1.5-5.80078 0l-13.80078 13.099609c-1.6 1.5-4.19883 1.5-5.79883 0L179.09961 1.125c-1.6-1.5-4.19883-1.5-5.79883 0L159.5 14.224609c-1.6 1.5-4.20078 1.5-5.80078 0L139.90039 1.125c-1.6-1.5-4.20078-1.5-5.80078 0l-13.79883 13.099609c-1.6 1.5-4.20078 1.5-5.80078 0L100.69922 1.125c-1.600001-1.5-4.198829-1.5-5.798829 0l-13.59961 13.099609c-1.6 1.5-4.200781 1.5-5.800781 0L61.699219 1.125c-1.6-1.5-4.198828-1.5-5.798828 0L42.099609 14.224609c-1.6 1.5-4.198828 1.5-5.798828 0L22.5 1.125C21.7.375 20.649609 0 19.599609 0z" fill="%231B331A"/></svg>')
      no-repeat bottom/100% auto;
  }
}

.notification__filter-bar,
.account__section-headline,
.content-wrapper,
.sidebar-wrapper--empty {
  background: $ui-base-color;
}

.about {
  &__section__title {
    background: lighten($ui-base-color, 4%);
  }

  &__meta {
    background: lighten($ui-base-color, 4%);
  }

  &__footer {
    color: $darker-text-color;
  }
}

.switch-to-advanced {
  color: $darker-text-color;
}

.compose-form {
  .spoiler-input {
    &__border {
      width: 0;
    }

    &__input {
      padding: 12px;
    }
  }
}

.simple_form {
  select,
  textarea,
  input[type]:not([type='hidden']) {
    background: darken($ui-base-color, 8%);
  }
}
